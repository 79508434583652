/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

#root {
  display: flex;
  min-height: 100vh;
  background-color: #f0f0f5; /* Background slightly different for distinction */
}

a {
  text-decoration: none;
  color: inherit;
}

/* Sidebar Styles */
.sidebar {
  width: 250px;
  background-color: #EA542B;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  height: 100%;
}

.sidebar .logo h2 {
  /* width: 100px; */
  color: white;
  margin-bottom: 30px;
}

.sidebar .menu {
  list-style: none;
  width: 100%;
}

.sidebar .menu li {
  margin-bottom: 15px;
}

.sidebar .menu a {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  color: white;
  font-size: 16px;
  background-color: transparent;
  border-radius: 5px;
  transition: background 0.3s ease, color 0.3s ease;
}

.sidebar .menu a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.sidebar .menu a.active {
  background-color: #e65a1e;
  font-weight: bold;
}

.menu-icon {
  margin-right: 10px;
  font-size: 20px;
}

.sidebar .menu a:hover .menu-icon,
.sidebar .menu a.active .menu-icon {
  color: #ffd700; /* Change icon color on hover and active state */
}

.sidebar .update-banner {
  margin-top: auto;
  margin-bottom: 20px;
  text-align: center;
}

.sidebar .update-banner a {
  color: white;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
}

.sidebar .user-info {
  color: white;
  font-size: 16px;
  text-align: center;
}

/* Main Content */
.main-content {
  margin-left: 260px;
  padding: 40px;
  height: 100vh;
  width: calc(100vw - 260px);
  background-color: #f4f5f7;
  overflow-x: hidden;
}

/* Header Styles */
.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.main-header input[type="search"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 40%;
}

.main-header .user-profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.logout-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.logout-button .menu-icon {
  margin-right: 10px;
}
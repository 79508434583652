

/* Client List Section */
.client-list {
    background-color: white; /* White background to stand out */
    border-radius: 15px; /* Rounded corners */
    padding: 30px; /* Padding inside the list section */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for better focus */
}

.client-list header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.client-list h1 {
    font-size: 24px;
    font-weight: bold;
}

/* Filters Section */
.filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filters input[type="search"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.filters select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Table Styles */
.client-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.client-table th,
.client-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.client-table th {
    background-color: #f5f5f5;
    font-size: 16px;
    font-weight: bold;
}

.client-table tr:hover {
    background-color: #f9f9f9;
}

.client-table a {
    color: #EA542B;
    font-weight: bold;
    cursor: pointer;
}

.client-table i {
    margin-right: 10px;
    cursor: pointer;
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.pagination button {
    padding: 10px 15px;
    background-color: white;
    border: 1px solid #ddd;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
}

.pagination button.active {
    background-color: #EA542B;
    color: white;
    border-color: #EA542B;
}

.pagination button:hover {
    background-color: #f1f1f1;
}

tyles CSS améliorés pour le popup de détails client

/* Styles pour le popup */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 500px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
}

.popup-content h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 10px;
}

.popup-content p {
    margin-bottom: 15px;
    line-height: 1.6;
    color: #555;
}

.popup-content strong {
    color: #333;
    font-weight: 600;
    margin-right: 5px;
}

.popup-close-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    display: block;
    margin-top: 20px;
    width: 100%;
}

.popup-close-btn:hover {
    background-color: #45a049;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .sidebar {
        width: 200px;
    }

    .main-content {
        margin-left: 200px;
        padding: 20px;
    }

    .client-list {
        padding: 20px;
    }

    .client-table th,
    .client-table td {
        padding: 10px;
    }

    .main-header input[type="search"],
    .filters input[type="search"] {
        width: 100%;
        margin-bottom: 10px;
    }

    .filters {
        flex-direction: column;
        align-items: flex-start;
    }
}

/* Stats Cards */
.stats-cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
}

.stats-cards .card {
    flex: 1;
    min-width: 200px;
    background-color: white;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stats-cards .card i.icon {
    font-size: 40px;
    color: #EA542B;
    margin-bottom: 15px;
}

.stats-cards .card p {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

.stats-cards .card span {
    color: #777;
    font-size: 14px;
}

/* Row Layout */
.row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
}

/* Client Statistics and Delivery Statistics */
.client-statistics, .delivery-statistics {
    flex: 1;
    min-width: 300px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    height: 100%;
}

.client-statistics h2, .delivery-statistics h2 {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #333;
}

.chart-container {
    position: relative;
    width: 100%;
    height: 300px;
}

.chart-container canvas {
    width: 100% !important;
    height: 100% !important;
}

/* Performance and Best Deliverers */
.performance, .best-deliverers {
    flex: 1;
    min-width: 300px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    height: 100%;
}

.performance h2, .best-deliverers h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.performance ul, .best-deliverers ul {
    list-style: none;
    padding: 0;
    font-size: 14px;
    color: #555;
}

.performance ul li, .best-deliverers ul li {
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;
}

.best-deliverers ul li:last-child {
    border-bottom: none;
}

.best-deliverers ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.best-deliverers ul li span:first-child {
    font-size: 16px;
    font-weight: bold;
}

.best-deliverers ul li span:last-child {
    font-size: 14px;
    color: #777;
}

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-content {
    position: absolute;
    z-index: 1000;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f9f9f9;
    color: #333;
    text-align: left;
    border-radius: 6px;
    padding: 10px;
    width: 200px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.tooltip-content::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #f9f9f9 transparent transparent transparent;
}

/* Responsive */
@media (max-width: 1024px) {
    .main-header input[type="search"] {
        width: 100%;
        margin-bottom: 20px;
    }

    .stats-cards {
        flex-direction: column;
    }

    .row {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .main-content {
        margin-left: 0;
        padding: 20px;
    }

    .stats-cards {
        gap: 10px;
    }
}

@media (max-width: 576px) {
    .stats-cards .card {
        min-width: 10%;
    }

    .client-statistics, .delivery-statistics, .performance, .best-deliverers {
        min-width: 100%;
    }
}

/* Font Awesome Icons */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

/* Delivery List Section */
.delivery-list {
    background-color: white; /* White background to stand out */
    border-radius: 15px; /* Rounded corners */
    padding: 30px; /* Padding inside the list section */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for better focus */
}

.delivery-list header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.delivery-list h1 {
    font-size: 24px;
    font-weight: bold;
}

.add-client-btn {
    padding: 10px 20px;
    background-color: #EA542B;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.add-client-btn:hover {
    background-color: #e65a1e;
}

/* Filters Section */
.filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filters input[type="search"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.filters select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Table Styles */
.delivery-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.delivery-table th,
.delivery-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.delivery-table th {
    background-color: #f5f5f5;
    font-size: 16px;
    font-weight: bold;
}

.delivery-table tr:hover {
    background-color: #f9f9f9;
}

.delivery-table a {
    color: #EA542B;
    font-weight: bold;
    cursor: pointer;
}

.delivery-table i {
    margin-right: 10px;
    cursor: pointer;
}

/* Styles pour le popup */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    max-height: 100vh;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-deliveryman-form {
    display: grid;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.submit-btn, .cancel-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-btn {
    background-color: #4CAF50;
    color: white;
}

.cancel-btn {
    background-color: #f44336;
    color: white;
}

.success-message {
    background-color: #dff0d8;
    color: #3c763d;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    text-align: center;
}

.error-message{
    background-color: #dff0d8;
    color: #f44336;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    text-align: center;
}

.no-deliverymen-message {
    text-align: center;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 4px;
    color: #666;
}


/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.pagination button {
    padding: 10px 15px;
    background-color: white;
    border: 1px solid #ddd;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
}

.pagination button.active {
    background-color: #EA542B;
    color: white;
    border-color: #EA542B;
}

.pagination button:hover {
    background-color: #f1f1f1;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .sidebar {
        width: 200px;
    }

    .main-content {
        margin-left: 200px;
        padding: 20px;
    }

    .delivery-list {
        padding: 20px;
    }

    .delivery-table th,
    .delivery-table td {
        padding: 10px;
    }

    .main-header input[type="search"],
    .filters input[type="search"] {
        width: 100%;
        margin-bottom: 10px;
    }

    .filters {
        flex-direction: column;
        align-items: flex-start;
    }
}


/* Order List Section */
.order-list {
    background-color: white; /* White background to stand out */
    border-radius: 15px; /* Rounded corners */
    padding: 30px; /* Padding inside the list section */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for better focus */
}

.order-list header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.order-list h1 {
    font-size: 24px;
    font-weight: bold;
}

/* Filters Section */
.filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.filters input[type="search"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.filters select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Table Styles */
.order-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.order-table th,
.order-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.order-table th {
    background-color: #f5f5f5;
    font-size: 16px;
    font-weight: bold;
}

.order-table tr:hover {
    background-color: #f9f9f9;
}

.order-table a {
    color: #EA542B;
    font-weight: bold;
    cursor: pointer;
}

.order-table i {
    margin-right: 10px;
    cursor: pointer;
}

/* Status Styles */
.status {
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    display: inline-block;
}

.status.pending {
    background-color: #fbc02d; /* Yellow for pending */
}

.status.in-progress {
    background-color: #2196f3; /* Blue for in-progress */
}

.status.recovered {
    background-color: #00bcd4; /* Cyan for recovered */
}

.status.canceled {
    background-color: #f44336; /* Red for canceled */
}

.status.delivered {
    background-color: #4caf50; /* Green for delivered */
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.pagination button {
    padding: 10px 15px;
    background-color: white;
    border: 1px solid #ddd;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
}

.pagination button.active {
    background-color: #EA542B;
    color: white;
    border-color: #EA542B;
}

.pagination button:hover {
    background-color: #f1f1f1;
}


/* Styles pour le popup */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 800px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
}

.popup-content h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 10px;
}

.order-details {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.detail-group {
    background-color: #f9f9f9;
    border-radius: 6px;
    padding: 15px;
}

.detail-group h3 {
    color: #4CAF50;
    font-size: 18px;
    margin-bottom: 10px;
}

.detail-group p {
    margin-bottom: 8px;
    line-height: 1.6;
    color: #555;
}

.detail-group strong {
    color: #333;
    font-weight: 600;
    margin-right: 5px;
}

.popup-close-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    display: block;
    margin-top: 20px;
    width: 100%;
}

.popup-close-btn:hover {
    background-color: #45a049;
}

/* Styles pour le bouton de vue dans la table */
.view-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #4CAF50;
    transition: color 0.3s ease;
}

.view-button:hover {
    color: #45a049;
    text-decoration: underline;
}

/* Amélioration du tableau des commandes */
.order-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 20px;
}

.order-table th,
.order-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.order-table th {
    background-color: #f8f8f8;
    font-weight: 600;
    color: #333;
}

.order-table tr:hover {
    background-color: #f5f5f5;
}

.status {
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
}

.status.pending { background-color: #FFF3CD; color: #856404; }
.status.in-progress { background-color: #CCE5FF; color: #004085; }
.status.recovered { background-color: #D4EDDA; color: #155724; }
.status.delivered { background-color: #D1ECF1; color: #0C5460; }
.status.canceled { background-color: #F8D7DA; color: #721C24; }

/* Responsive design */
@media (max-width: 768px) {
    .popup-content {
        padding: 20px;
    }

    .order-table {
        font-size: 14px;
    }

    .order-table th,
    .order-table td {
        padding: 8px 10px;
    }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .sidebar {
        width: 200px;
    }

    .main-content {
        margin-left: 200px;
        padding: 20px;
    }

    .order-list {
        padding: 20px;
    }

    .order-table th,
    .order-table td {
        padding: 10px;
    }

    .main-header input[type="search"],
    .filters input[type="search"] {
        width: 100%;
        margin-bottom: 10px;
    }

    .filters {
        flex-direction: column;
        align-items: flex-start;
    }
}

/* Styles de base pour le corps de la page */
body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Conteneur principal */
.login-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 8px; /* Ajout d'un rayon pour des coins arrondis */
    overflow: hidden; /* Assurer que le contenu ne dépasse pas les coins arrondis */
}

/* Section de l'image */
.login-image {
    flex: 10;
    background-color: #fff;
}

.login-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Section du formulaire de connexion */
.login-form {
    flex: 6;
    padding: 40px 100px; /* Combiner padding vertical et horizontal */
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
}

.login-form h2 {
    margin-bottom: 30px;
    text-align: center;
    font-size: 28px;
    color: #333;
}

.form-group {
    margin-bottom: 25px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s;
}

.form-group input:focus {
    border-color: #EA542B; /* Couleur primaire lors du focus */
    outline: none; /* Supprimer le contour par défaut */
    box-shadow: 0 0 5px rgba(234, 84, 43, 0.5); /* Ajouter une ombre pour le focus */
}

.form-group button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #EA542B; /* Couleur primaire */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.form-group button:hover {
    background-color: #c74324; /* Variante plus foncée de la couleur primaire */
}

/* Réactivité sur les petits écrans */
@media (max-width: 1024px) {
    .login-container {
        width: 95%;
        height: auto;
        flex-direction: column;
    }

    .login-image, .login-form {
        flex: none;
        width: 100%;
    }

    .login-image {
        height: 250px;
    }

    .login-form {
        padding: 30px 50px;
    }
}

@media (max-width: 768px) {
    .login-image {
        height: 200px;
    }

    .login-form {
        padding: 20px 30px;
    }

    .login-form h2 {
        font-size: 24px;
    }

    .form-group input, .form-group button {
        font-size: 14px;
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .login-container {
        flex-direction: column;
    }

    .login-image {
        height: 150px;
    }

    .login-form {
        padding: 15px 20px;
    }

    .login-form h2 {
        font-size: 20px;
    }

    .form-group input, .form-group button {
        font-size: 14px;
        padding: 8px;
    }
}
